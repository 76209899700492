<template>
  <page-container title="菜单管理" :breadcrumb="breadcrumb">
    <div class="menu-container">
      <div class="box-container" style="margin-top: 24px; width: 300px; padding-bottom: 24px;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow: auto;">
          <a-tree
            :default-expand-all="false"
            :default-expanded-keys="['sys']"
            :selectedKeys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'menuname', key:'menuid'}"
            :tree-data="menuTree"
            @select="selectMenu"
          >
            <span slot="customTreeNode" slot-scope="node">
              <span>{{node.dataRef.menuname}}</span>
              <a-tooltip>
                <template slot="title">新增下级菜单</template>
                <a-icon type="plus" @click.stop="addChildQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title">删除菜单</template>
                <a-icon type="delete" @click.stop="deleteQuick(node)" style="margin-left: 7px;" />
              </a-tooltip>
            </span>
          </a-tree>
        </div>
      </div>
      <div class="box-container" style="margin-top: 24px; flex: 1; padding-bottom: 24px; padding-left: 0;">
        <div class="box-container-inner" style="min-height: 600px;">
          <a-form-model ref="menuForm" :model="formDatas" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
            <a-form-model-item :wrapper-col="{ span: 22, offset: 0 }" style="width: 100%; margin-bottom: 20px; overflow: hidden;">
              <a-button v-if="selectedMenu && formType=='edit'" size="large" @click="addChild" style="margin-left: 10px;float: right;"><a-icon type="plus"></a-icon>新增下级菜单</a-button>
              <a-button v-if="selectedMenu && 'sys'!=formDatas.menuid && formType=='edit'" size="large" type="danger" @click="deleteConfirm" style="margin-left: 10px;float: right;">删除</a-button>
              <a-button v-if="selectedMenu && 'sys'!=formDatas.menuid" size="large" type="primary" @click="save" style="float: right;">保存</a-button>
            </a-form-model-item>
            <a-form-model-item label="父菜单" prop="parentmenuid">
              <a-input :value="parentmenuname" disabled />
            </a-form-model-item>
            <a-form-model-item label="菜单编号" prop="menucode">
              <a-input :addon-before="formDatas.parentmenucode" v-model.trim="formDatas.menucode" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
            <a-form-model-item label="菜单名称" prop="menuname">
              <a-input v-model.trim="formDatas.menuname" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
            <a-form-model-item label="菜单类型" prop="menutype">
              <a-select v-model="formDatas.menutype" :disabled="'sys' == formDatas.menuid">
                <a-select-option v-for="item in menutypes" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="菜单URL" prop="menuurl">
              <a-input v-model.trim="formDatas.menuurl" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
            <a-form-model-item label="图标" prop="menupic">
              <a-input v-model.trim="formDatas.menupic" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
            <a-form-model-item label="菜单状态" prop="status">
              <a-radio-group v-model="formDatas.status" :disabled="'sys' == formDatas.menuid">
                <a-radio :value="0">启用</a-radio>
                <a-radio :value="1">停用</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="终端类型" prop="apptype">
              <a-select v-model="formDatas.apptype" :disabled="'sys' == formDatas.menuid">
                <a-select-option v-for="item in apptypes" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="序号" prop="menuno">
              <a-input v-model.trim="formDatas.menuno" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="menudesc">
              <a-input v-model.trim="formDatas.menudesc" :disabled="'sys' == formDatas.menuid" />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getAllMenuTree, addMenu, editMenu, deleteMenu } from 'A/xtpz.js'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import apptypes from '@/json/apptypes'
import menutypes from '@/json/menutypes'

export default {
  name: 'menuManagement',
  data() {
    return {
      apptypes,
      menutypes,
      breadcrumb: [
        {
          name: '系统管理',
          path: ''
        },
        {
          name: '菜单管理',
          path: ''
        },
      ],
      menuTree: [
        {
          parentmenuid: '',
          menuid: 'sys',
          menucode: '',
          menuname: '"卫全物管"全域监管平台',
          menutype: 1,
          menuurl: '',
          menuno: '1',
          status: 0,
          apptype: '',
          menudesc: '',
          children: [],
          scopedSlots: {title: 'customTreeNode'}
        },
      ],
      selectedKeys: [],
      selectedMenu: null,
      formType: 'edit',
      formDatas: {
        menuid: '',
        parentmenuid: '',
        parentmenucode: '',
        menucode: '',
        menuname: '',
        menutype: 1,
        menuurl: '',
        menupic: '',
        menuno: '',
        status: 0,
        apptype: 1,
        menudesc: '',
      },
      formRules: {
        menucode: [
          {required: true, message: '请输入菜单编号'},
          {pattern: /^\d{2}$/, message: '请输入两位数字', trigger: 'blur'}
        ],
        menuname: [{required: true, message: '请输入菜单名称'}],
        menuno: [
          {required: true, message: '请输入菜单序号'},
          {pattern: /^\d+$/, message: '请输入数字'}
        ],
      }
    }
  },
  computed: {
    parentmenuname() {
      let result = '';
      if(this.formDatas.parentmenuid) {
        let parentMenu = getItemFromArrayByKey(this.menuTree, 'menuid', this.formDatas.parentmenuid);
        if(parentMenu) {
          result = parentMenu.menuname;
        }
      }
      return result;
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMenuTree();
    },
    getMenuTree() {
      this.showLoading();
      getAllMenuTree().then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.menuTree[0].children = this.getMenuTreeSlots(res.item);
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getMenuTreeSlots(menuTree) {
      return menuTree.map(item => {
        item.scopedSlots = {title: 'customTreeNode'}
        if(item.children && item.children.length) {
          item.children = this.getMenuTreeSlots(item.children);
        }
        return item;
      })
    },
    selectMenu(item) {
      let menuid = item[0];
      if(menuid) {
        this.selectedMenu = getItemFromArrayByKey(this.menuTree, 'menuid', menuid);
        this.setFormData('edit');
      }else {
        this.resetFormData();
        this.selectedMenu = null;
      }
    },
    setFormData(type) {
      this.formType = type;
      this.resetFormData();
      if('edit' == this.formType) {
        this.formDatas.menuid = this.selectedMenu.menuid;
        this.formDatas.parentmenuid = this.selectedMenu.parentmenuid;
        if(this.selectedMenu.menucode.length > 2) {
          this.formDatas.parentmenucode = this.selectedMenu.menucode.slice(0, -2);
          this.formDatas.menucode = this.selectedMenu.menucode.slice(-2);
        }else {
          this.formDatas.parentmenucode = '';
          this.formDatas.menucode = this.selectedMenu.menucode;
        }
        this.formDatas.menuname = this.selectedMenu.menuname;
        this.formDatas.menutype = this.selectedMenu.menutype;
        this.formDatas.menuurl = this.selectedMenu.menuurl;
        this.formDatas.menupic = this.selectedMenu.menupic;
        this.formDatas.menuno = this.selectedMenu.menuno;
        this.formDatas.status = this.selectedMenu.status;
        this.formDatas.apptype = this.selectedMenu.apptype;
        this.formDatas.menudesc = this.selectedMenu.menudesc;
      }else {
        this.selectedKeys = [];
        this.formDatas.parentmenuid = this.selectedMenu.menuid;
        this.formDatas.parentmenucode = this.selectedMenu.menucode;
      }
    },
    resetFormData() {
      this.$refs.menuForm.clearValidate();
      this.formDatas.menuid = '';
      this.formDatas.parentmenuid = '';
      this.formDatas.parentmenucode = '';
      this.formDatas.menucode = '';
      this.formDatas.menuname = '';
      this.formDatas.menutype = 1;
      this.formDatas.menuurl = '';
      this.formDatas.menupic = '';
      this.formDatas.menuno = '1';
      this.formDatas.status = 0;
      this.formDatas.apptype = 1;
      this.formDatas.menudesc = '';
    },
    addChildQuick(node) {
      this.selectedMenu = node.dataRef;
      this.setFormData('edit');
      this.addChild();
    },
    deleteQuick(node) {
      this.selectedMenu = node.dataRef;
      this.setFormData('edit');
      this.deleteConfirm();
    },
    addChild() {
      if(this.selectedMenu) {
        this.setFormData('add');
      }else {
        this.$message.info('请选择一条菜单');
      }
    },
    save() {
      this.$refs.menuForm.validate(valid => {
        if(valid) {
          let params = {
            ...this.formDatas
          };
          params.menucode = params.parentmenucode + params.menucode;
          if(params.parentmenuid  == 'sys') {
            params.parentmenuid = '';
          }
          this.showLoading();
          if(this.formType == 'edit') {
            editMenu(params).then(res => {
              this.hideLoading();
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                this.getMenuTree();
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            })
          }else {
            addMenu(params).then(res => {
              this.hideLoading();
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                this.getMenuTree();
                this.setFormData('edit');
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            })
          }
        }
      })
    },
    deleteConfirm() {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete();
      }).catch(()=>{
      });
    },
    delete() {
      this.showLoading();
      let params = {
        menuid: this.formDatas.menuid
      }
      deleteMenu(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.getMenuTree();
          this.resetFormData();
          this.selectedMenu = null;
          this.selectedKeys = [];
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  display: flex;
}
</style>